import Vue from 'vue'
import Router from 'vue-router'
import store from "../store/index";
import Login from '../views/login.vue'
import SignUp from '../views/signup.vue'
import ForgotPass from '../views/forgot-pass.vue'
import index from '../components'


import Dashboard from "../pages/dashboard";

import Customers from "../pages/customers/index";
import CustomerMpesa from "../pages/customers/mpesa";
import CustomerTrans from "../pages/customers/trans";
import CustomerReward from "../pages/customers/reward";

import Roles from "../pages/system-roles/index";
import Configs from "../pages/system-configs/index";

// Survey
import Profiles from "../pages/profiles/index";

// User
import User from "../pages/system-users/index";
import userLogs from "../pages/system-users/logs";
import addUser from "../pages/system-users/addUser";
import editUser from "../pages/system-users/editUser";

// transaction
import Inbox from "../pages/inbox/index"

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/signup', name: 'signup', component: SignUp, meta: { guest: true } },
        { path: '/forgot-password', name: 'forgot', component: ForgotPass, meta: { guest: true } },
        { path: '/', name: 'login', component: Login, meta: { guest: true }
        },
        {
            path: '/app',
            component: index,
            children: [
                { path: '', name: 'dashboard', component: Dashboard, meta: { permission: 'Read Statistics', requiresAuth: true }},

                { path: 'customers', name: 'customers', component: Customers, meta: { permission: 'Customers', requiresAuth: true }},
                { path: 'customers/mpesa', name: 'customer-mpesa', component: CustomerMpesa, meta: { permission: 'Customers', requiresAuth: true }},
                { path: 'customers/trans', name: 'customer-trans', component: CustomerTrans, meta: { permission: 'Customers', requiresAuth: true }},
                { path: 'customers/reward', name: 'customer-reward', component: CustomerReward, meta: { permission: 'Customers', requiresAuth: true }},

                { path: 'roles', name: 'roles', component: Roles, meta: { permission: 'Read System Roles', requiresAuth: true }},
                { path: 'configs', name: 'configs', component: Configs, meta: { permission: 'Read System Configurations', requiresAuth: true }},

                { path: 'user/:id(\\d+)?', name: 'user', component: User, meta: { permission: 'Read Admin Users', requiresAuth: true }},
                { path: 'user/add', name: 'addUser', component: addUser, meta: { permission: 'Add User', requiresAuth: true }},
                { path: 'user/edit', name: 'editUser', component: editUser, meta: { permission: 'Edit User', requiresAuth: true }},
                { path: 'user/logs', name: 'userLogs', component: userLogs, meta: { permission: 'User Logs', requiresAuth: true }},

                { path: 'profiles', name: 'profiles', component: Profiles, meta: { permission: 'Read Profiles', requiresAuth: true }},

                { path: 'inbox', name: 'inbox', component: Inbox, meta: { permission: 'Inbox', requiresAuth: true }},
            ]
        },
    ]
})


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next("/");
    } else {
        next();
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/app/");
            return;
        }
        next();
    } else {
        next();
    }
})


export default router
